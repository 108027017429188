import React from 'react';
import { unitsTable } from 'components/DataTable/DataTableModels';
import { createProductUnit, routes } from 'routes';
import userIcon from 'static/images/user_icon.svg';
import { Container } from 'components/Container';
import { IconCard } from 'components/IconCard';
import { DataTable } from 'components/DataTable/DataTable';
import { useApi } from 'lib/useApi';
import { PortalContext } from 'PortalContext';
import { Link, useParams } from 'react-router-dom';
import { ContainerHead } from 'components/ContainerHead';
import { DataContainer } from 'components/DataContainer';
import { Facility, Product, ProductTypes, Unit } from 'models/ApiModels';
import { Flex, Button, Text, Select, Input, useToast  } from '@chakra-ui/react';
import { Icon } from 'components/Icon';
import { KeyIcon } from 'lib/IconLib';
import { AddIcon } from '@chakra-ui/icons';
import { delApi, postApi, fetchApi } from 'lib/fetchApi';

export function ProductPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { data: unitsResp, state: respState } = useApi<Unit[]>(
    `/units/product/${id}`
  );
  const { data: product } = useApi<Product>(`/products/${id}`);
  const { dispatch } = React.useContext(PortalContext);
  const [distinctFacilities, setDistinctFacilities] = React.useState<Facility[]>([]);
  const [selectedFacility, setSelectedFacility] = React.useState<string>("");
  const [numericValue, setNumericValue] = React.useState<number | string>("");


  const toast = useToast();

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE', payload: respState });
  }, [dispatch, respState]);

  React.useEffect(() => {
    // Only run when unitsResp is available
    if (unitsResp && unitsResp.length > 0) {
      const facilitiesMap = new Map();
    
      unitsResp.forEach((unit) => {
        const facility = unit.facility;
        facilitiesMap.set(facility.id, facility); // Ensure uniqueness by id
      });
    
      setDistinctFacilities(Array.from(facilitiesMap.values())); // Extract unique facility objects
    }
    
  }, [unitsResp]); // Dependency array: run when unitsResp changes

  if (respState !== 'success') return <></>;
  if (respState === 'success' && !respState?.length)
    return <p>Ingen data {respState}</p>;

  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFacility = event.target.value;
    setSelectedFacility(selectedFacility);
  };
  const handleNumericChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNumericValue(value);
  };
  const handleSubmitSave = async () => {
    // Only send data if a facility is selected and numeric value is provided
    if (selectedFacility && numericValue !== "") {
      const data = {
        facilityId: Number(selectedFacility),
        productId: Number(id),
        price: Number(numericValue), // Send the numeric value as well
      };

      try {
          const addPriceOverrides = await postApi('/PriceOverride', {
                    ...data
                });
                toast({
                  title: `Prisjustering skapad!`,
                  status: 'success',
                  position: 'top',
                  isClosable: true,
                });
  
        // Do something with filtered units (update state, display, etc.)
      } catch (error) {
        console.error("Error fetching filtered units:", error);
      }
    }
  };
  const handleSubmitDelete = async () => {
    // Only send data if a facility is selected and numeric value is provided
    if (selectedFacility) {
      const data = {
        facilityId: Number(selectedFacility),
        productId: Number(id) // Send the numeric value as well
      };

      try {
          const addPriceOverrides = await delApi('/PriceOverride', {
                    ...data
                });
                toast({
                  title: `Prisjustering borttagen!`,
                  status: 'success',
                  position: 'top',
                  isClosable: true,
                });
                
        // Do something with filtered units (update state, display, etc.)
      } catch (error) {
        console.error("Error fetching filtered units:", error);
      }
    }
  };

  return (
    <>
      <ContainerHead title="Produktartiklar">
        <DataContainer title="Namn">
          {product?.name ?? 'Inget namn'}
        </DataContainer>
        <DataContainer title="Beskrivning">
          {product?.description !== ''
            ? product?.description
            : 'Ingen beskrivning'}
        </DataContainer>
        <DataContainer title="Månadspris">
          {product?.price ?? '0'}:-
        </DataContainer>
        <DataContainer title="Produkttyp">
          {ProductTypes[product?.type ?? 0]}
        </DataContainer>
        <DataContainer title="Storlek">{product?.size ?? '0'} m<sup>2</sup></DataContainer>
      </ContainerHead>
      <Container>
        <Flex mb={5}>
          <Link to={routes.editProduct.path.replace(':id', id)}>
            <Button variant="primary"><Icon fill="white" icon={KeyIcon} /><Text ml={3}>{routes.editProduct.linkText}</Text></Button>
          </Link>
          <Link to={createProductUnit.path.replace(':id', id)}>
            <Button variant="primary" ml={5}><AddIcon /><Text ml={3}>{createProductUnit.linkText}</Text></Button>
          </Link>
        </Flex>
        <Flex>

        <Flex width={350}>
        <Select placeholder="Prisjustera anläggning" onChange={handleSelectChange} >
        {distinctFacilities.length > 0 ? (
          distinctFacilities.map((facility) => (
            <option key={facility.id} value={facility.id}>
              {facility.name}
            </option>
          ))
        ) : (
          <option disabled>No facilities available</option>
        )}
    </Select>
    </Flex>
    <Flex width={100} ml={5}>
    {selectedFacility && (
          <Input
            type="number"
            value={numericValue}
            onChange={handleNumericChange}
            placeholder="Enter a numeric value"
            min={0}  
            />
          )}
            </Flex>

        <Flex ml={5}>
        {selectedFacility && (
  <Button onClick={handleSubmitSave} variant="primary">
    <Text>{"Spara"}</Text>
  </Button>
)}
        </Flex>
        <Flex ml={5}>
        {selectedFacility && (
  <Button onClick={handleSubmitDelete} variant="primary">
    <Text>{"Ta bort"}</Text>
  </Button>
)}
        </Flex>
       </Flex>
        <DataTable
          title="Artiklar"
          tableDef={unitsTable}
          data={unitsResp}
          showSearch={true}
          searchableFields={[
            'status',
            'objectID',
            'externalId',
            'product.size',
          ]}
        />
      </Container>
    </>
  );
}
